window.arjoJs = window.arjoJs || {
  isMobile: function () {
    return !this.isTablet();
  },
  isTablet: function () {
    return window.matchMedia('(min-width:48rem)').matches;
  },
  isDesktop: function () {
    return window.matchMedia('(min-width:64rem)').matches;
  },
  isTabletOnly: function () {
    return this.isTablet() && !this.isDesktop();
  },
  setGlobalSizes: function () {
    const bodyStyle = document.body.style;

    const clientWidth = document.body.clientWidth;
    bodyStyle.setProperty("--page-width", clientWidth + "px");

    const containerWidth = Math.min(1600, clientWidth - (this.isMobile() ? 40 : 120));
    bodyStyle.setProperty("--container-width", containerWidth + "px");
  },
  calculateViewportHeight: () => {
    document.body.style.setProperty("--viewport-height", window.innerHeight + "px");
  },
};

Element.prototype.toggleAttribute = Element.prototype.toggleAttribute || function (attr) {
  if (this.hasAttribute(attr)) {
    this.removeAttribute(attr);
  } else {
    this.setAttribute(attr, "");
  }
};

Element.prototype.observeResize = Element.prototype.observeResize || function (callback) {
  if ("ResizeObserver" in window) {
    // create an Observer instance
    const resizeObserver = new ResizeObserver(callback);

    // start observing a DOM node
    resizeObserver.observe(this);
  } else {
    let lastRect = this.getBoundingClientRect();

    setInterval(() => {
      const rect = this.getBoundingClientRect();
      if (Math.floor(rect.width) != Math.floor(lastRect.width) || Math.floor(rect.height) != Math.floor(lastRect.height)) {
        lastRect = rect;
        callback();
      }
    }, 500);
  }
};

Element.prototype.scrollIntoViewEx = function (extraOffset = 5, additionalStickyElements) {
  let offset = 0;

  // Get the sticky header
  var nav = window.arjoJs.isDesktop()
    ? document.querySelector(".arjo-m-primary-nav")
    : document.querySelector(".arjo-o-header");
  if (nav) {
    // Set initial offset equals to sticky header's height
    var navRect = nav.getBoundingClientRect();
    offset = navRect.height;
  }

  // If have any additional sticky element, add its height to offset
  if (additionalStickyElements) {
    if (additionalStickyElements.constructor === Array) {
      additionalStickyElements.forEach(element => {
        if (!element) {
          return;
        }

        var rect = element.getBoundingClientRect();
        offset += rect.height;
      });
    }
  }
  window.scroll(0, this.offsetTop - offset - extraOffset);
};

window.arjoJs.setGlobalSizes();
window.arjoJs.calculateViewportHeight();

addEventListener("resize", () => {
  window.arjoJs.setGlobalSizes();
  window.arjoJs.calculateViewportHeight();
});

window.arjoEventHandlers = window.arjoEventHandlers || (function () {
  let eventHandlers = [];

  window.addEventListener('load', () => setTimeout(_execute, 0));

  function _execute() {
    eventHandlers = eventHandlers.sort((a, b) => (a.order < b.order ? -1 : 1));
    eventHandlers.forEach((item) => item.callback());
  }

  function add(key, callback, order) {
    if (!eventHandlers.some((item) => item.key === key)) {
      eventHandlers.push({ key, callback, order });
    }
  }

  return {
    add
  }
})();

